import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'

import AOS from 'aos';
import 'aos/dist/aos.css';

const { defaultLanguage } = require('../../prismic-configuration')

const getRootLink = (lang) => {
  return lang === defaultLanguage ? '/' : `/${lang}`
}

const PageTemplate = (props) => {
  const { data, pageContext } = props;
  if (!data) return null

  const {id, lang, isRobots} = pageContext;

  useEffect( ()=> {
    AOS.init({
      once: true,
    });
  }, [])

  const homepage = data.prismicPage || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
  }

  const topMenu = data.prismicTopmenu || {}
  const footer = data.prismicFooter || {}
  const rootLink = getRootLink(lang);

  return (
    <Layout seo={seo} topMenu={topMenu.data} footer={footer}>
      <div className="border-bottom" style={{paddingTop:"80px"}}>
        {!homepage.data.hide_title &&
        <div className="bg-light title-section-p-y">
          <div className="container">
            <h1 className="page-title">{homepage.data.page_title}</h1>
          </div>
        </div>
        }
        {!homepage.data.hide_title &&
        <div className="container">
          <div className="btn-back-m"><a href={rootLink} className="p-2 ps-0 text-link text-uppercase text-decoration-none d-flex align-items-center"><img className="me-2 details-icon" height="12" width="12" src={`/icons/back.svg`}/> BACK TO HOME</a></div>
        </div>
        }
        <SliceZone slices={homepage.data.body} components={components} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        seo_title
        seo_description
        seo_keywords
        page_title
        hide_title
        body { 
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyHeaderSimple
					...PageDataBodyTitleAndText
          ...PageDataBodyContactFormAndInfo
          ...PageDataBodyPartners
          ...PageDataBodyCallToAction
          ...PageDataBodyImageAndText
          ...PageDataBodyFeatures
          ...PageDataBodySteps
        }
      }
    }
    prismicTopmenu {
      ...TopmenuFragment
    }
    prismicFooter {
      ...FooterFragment
    }
  }
`

export default withPrismicPreview(PageTemplate)
